import React, { useContext, useState } from 'react';
import { GeneralContext } from '@/context/GeneralContext';
import { useLocation, useNavigate } from 'react-router-dom';

import { ChatLines, MenuScale, MoreHoriz, NavArrowLeft } from 'iconoir-react';
import styled from 'styled-components';
import * as Styles from '../../styles/ChatHeaderStyles';
import { checkUserEmptyOrGuest, getChatIdFromUrl } from '@/util/utils';
import SideBarLogo from '@/assets/svg/SideBarLogo';
import FileListModalMobile from '@/pages/modals/FileListModalMobile';
import Popover from '@mui/material/Popover';
import { useUserStateOnly } from '@/context/useUser';
import { useUnreadChats } from '../../hooks/useUnreadChats';
import { DashboardIcon } from '@/assets/svg/DashboardIcon';

const ChatHeaderMobile = ({ chatData, details, setDetails, files }) => {
    const context = useContext(GeneralContext);
    const setOpenModal = context.setOpenModal;
    const navigate = useNavigate();
    const conversationId = getChatIdFromUrl();
    const location = useLocation();
    const { user, isLoading } = useUserStateOnly();
    const isRequest = location.pathname.includes('/request/');
    const request = false;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const { data: unread } = useUnreadChats();

    if (isLoading) {
        return;
    }
    if (checkUserEmptyOrGuest(user)) {
        if (context.width < 768) {
            return (
                <div className={`flex p-5 justify-between items-center`}>
                    <MenuScale
                        onClick={() => {
                            context.setMobileSidebar(true);
                        }}
                        color={context.dark ? 'white' : 'black'}
                        strokeWidth={'1px'}
                    />
                </div>
            );
        }
        return;
    }

    return (
        <>
            {context.openModal === 'files' && (
                <FileListModalMobile files={files} />
            )}
            {location && location.pathname === '/' && context.width < 768 ? (
                <div className={`flex p-5 justify-between items-center`}>
                    <NavArrowLeft
                        opacity={0.5}
                        strokeWidth={2}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate('/');
                        }}
                    />
                    <SideBarLogo
                        height={'20'}
                        color={context.dark ? 'white' : '#141718'}
                    />

                    <div
                        className="relative cursor-pointer"
                        onClick={() => {
                            navigate('/dashboard');
                            setOpenModal('/');
                        }}
                    >
                        <DashboardIcon dark={context.dark} />
                        {unread && unread.length > 0 && (
                            <div
                                style={{
                                    border: context.dark
                                        ? '2px solid #141718'
                                        : '2px solid white',
                                    paddingTop: '1px',
                                }}
                                className="absolute select-none inline-flex items-center justify-center w-6 h-6 text-xs font-medium text-white bg-accent border-2 rounded-full -top-3 -end-3"
                            >
                                {unread.length > 99 ? 99 : unread.length}
                            </div>
                        )}
                    </div>
                </div>
            ) : isRequest ? (
                details ? (
                    <Styles.MobileChatTitleWrapper detail={true}>
                        <Styles.TitleGrid
                            newchat={conversationId === undefined}
                        >
                            <MobileTopLeft>
                                <NavArrowLeft
                                    height={24}
                                    width={24}
                                    strokeWidth={'2'}
                                    color={'white'}
                                    onClick={() => {
                                        setOpenModal('chats');
                                        navigate('/');
                                    }}
                                />
                            </MobileTopLeft>
                            <Styles.MobileChatTitle style={{ color: 'white' }}>
                                {request && request.summary}
                            </Styles.MobileChatTitle>
                            <MobileTopRight>
                                <ChatLines
                                    onClick={() => {
                                        setDetails(false);
                                    }}
                                    height={24}
                                    width={24}
                                    fill={'white'}
                                    color={'black'}
                                />
                            </MobileTopRight>
                        </Styles.TitleGrid>
                        <div>
                            <button
                                style={{
                                    paddingTop: '7px',
                                    paddingBottom: '5px',
                                }}
                                onClick={() => setDetails(d => !d)}
                                className="flex items-start space-x-10 mt-4 px-3 rounded-2xl bg-accent text-white font-medium text-base leading-snug"
                            >
                                Review
                            </button>
                        </div>
                    </Styles.MobileChatTitleWrapper>
                ) : (
                    <Styles.MobileChatTitleWrapper>
                        <Styles.TitleGrid
                            newchat={conversationId === undefined}
                        >
                            <MobileTopLeft>
                                <NavArrowLeft
                                    height={24}
                                    width={24}
                                    color={context.dark ? 'white' : 'black'}
                                    strokeWidth={'2'}
                                    onClick={() => {
                                        setDetails(true);
                                        // setOpenModal('chats');
                                        // navigate('/');
                                    }}
                                />
                            </MobileTopLeft>
                            <Styles.MobileChatTitle dark={context.dark}>
                                {request && request.summary}
                            </Styles.MobileChatTitle>
                            <div style={{ width: '24px' }} />
                        </Styles.TitleGrid>
                    </Styles.MobileChatTitleWrapper>
                )
            ) : (
                location &&
                location.pathname !== '/' && (
                    <div className={`flex p-5 justify-between items-center`}>
                        {context.width < 768 && (
                            <NavArrowLeft
                                opacity={0.5}
                                strokeWidth={2}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    navigate('/');
                                }}
                            />
                        )}
                        <Styles.MobileChatTitle dark={context.dark}>
                            {!isRequest
                                ? chatData?.conversation?.goal
                                : request && request.summary}
                        </Styles.MobileChatTitle>
                        <div>
                            <MoreHoriz
                                color={context.dark ? 'white' : 'black'}
                                onClick={handleClick}
                            />
                            <FilesPopover
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                            />
                        </div>
                    </div>
                )
            )}
        </>
    );
};

export default ChatHeaderMobile;

const FilesPopover = ({ anchorEl, setAnchorEl }) => {
    const context = useContext(GeneralContext);
    const conversationId = getChatIdFromUrl();

    const leftOpen = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Popover
            disableAutoFocus={true}
            open={leftOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            PaperProps={{
                sx: {
                    marginTop: '15px',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 40px 0px rgba(0, 0, 0, 0.14);',
                },
            }}
        >
            <div className="rounded-lg bg-white text-black dark:text-white dark:bg-black shadow-lg gap-5 p-2 ">
                <div
                    className="flex flex-row gap-2.5 text-base font-medium leading-relaxed py-2.5 px-4 cursor-pointer no-underline hover:bg-lightGray dark:hover:bg-darkGray rounded-lg"
                    onClick={() => {
                        context.setOpenModal('files');
                    }}
                >
                    Files
                </div>
                <div
                    className="flex flex-row gap-2.5 text-base text-alertRed font-medium leading-relaxed py-2.5 px-4 cursor-pointer no-underline hover:bg-lightGray dark:hover:bg-darkGray rounded-lg"
                    onClick={() => {
                        context.setDeleteOpen(conversationId);
                    }}
                >
                    Delete conversation
                </div>
            </div>
        </Popover>
    );
};

const MobileTopLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    justify-self: start;
`;
const MobileTopRight = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-self: end;
`;
