import React, { useContext, useEffect, useRef, useState } from 'react';
import { Plus, SendDiagonalSolid } from 'iconoir-react';
import { GeneralContext } from '@/context/GeneralContext';
import { RetellWebClient } from 'retell-client-js-sdk';
import config from '@/util/config';
import { getChatIdFromUrl } from '@/util/utils';
import { axiosDefault } from '@/util/axiosRetry';
import '@/styles/voiceChatButton.css';
import { useActivityList } from '../../hooks/useActivityList';

// Web Call Agent Id
// const agentId = '904fac82d4144e76fc69a25fc5761fab';

// Custom LLM Agent Id
const agentId = null;

const retellWebClient = new RetellWebClient();

const ChatInput = ({
    setActiveInput,
    inputMessageText,
    setInputMessageText,
    sendMessage,
    active,
}) => {
    // do the same thing whether click or keypress
    const context = useContext(GeneralContext);
    const [isCalling, setIsCalling] = useState(false);
    const { data } = useActivityList();

    const textareaRef = useRef(null);

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight + 4}px`;
        }
    }, [inputMessageText]);

    useEffect(() => {
        // Setup event listeners
        retellWebClient.on('call_started', () => {
            console.log('call started');
        });

        retellWebClient.on('call_ended', ({ code, reason }) => {
            console.log('Closed with code:', code, ', reason:', reason);
            setIsCalling(false); // Update button to 'Start' when conversation ends
        });

        // When agent starts talking for the utterance
        // useful for animation
        retellWebClient.on('agent_start_talking', () => {
            console.log('agent_start_talking');
        });

        // When agent is done talking for the utterance
        // useful for animation
        retellWebClient.on('agent_stop_talking', () => {
            console.log('agent_stop_talking');
        });

        retellWebClient.on('audio', audio => {
            // Real time pcm audio bytes being played back, in format of Float32Array
            // only available when emitRawAudioSamples is true
            // console.log('There is audio');
        });

        retellWebClient.on('metadata', metadata => {
            console.log('metadata', metadata);
        });

        retellWebClient.on('error', error => {
            console.error('An error occurred:', error);
            retellWebClient.stopCall();
            setIsCalling(false); // Update button to 'Start' in case of error
        });

        retellWebClient.on('update', update => {
            // Update message such as transcript
            // You can get transcript with update.transcript
            // Please note that transcript only contains last 5 sentences to avoid the payload being too large
            // Print live transcript as needed
            console.log('update', update);

            if (update.turntaking) {
                console.log('update', update);

                switch (update.turntaking) {
                    case 'user_turn':
                        //console.log('transcript', update.transcript);
                        if (update.transcript) {
                            // get the last message from update.transcript that has role === 'user'
                            const userMessage = update.transcript
                                .filter(message => message.role === 'user')
                                .pop();

                            // potentially write the message to API, and chat history, or we can just write messages on API from the webhook events
                        }
                        break;
                    case 'agent_turn':
                        //console.log('metadata', update.metadata);
                        break;
                    default:
                    // nothing
                }
            } else {
                console.log('update', update);
            }
        });
    }, []);

    const toggleConversation = async () => {
        if (isCalling) {
            retellWebClient.stopCall();
        } else {
            const createCallResponse = await createWebCall(agentId);
            console.log('Create call response: ', createCallResponse);

            if (createCallResponse.call_id) {
                retellWebClient
                    .startCall({
                        //callId: createCallResponse.call_id,
                        sampleRate: 44100,
                        enableUpdate: true,
                        accessToken: createCallResponse.access_token,
                    })
                    .catch(console.error);
                setIsCalling(true); // Update button to 'Stop' when conversation starts
            }
        }
    };

    async function createWebCall(agentId) {
        try {
            const currentUser = JSON.parse(localStorage.getItem('user'));

            const userId = currentUser ? currentUser.id : null;

            let conversationId = getChatIdFromUrl();

            if (!conversationId) {
                sendMessage("Let's use voice");

                conversationId = getChatIdFromUrl();
            }

            console.log('conversationId', conversationId);

            const response = await axiosDefault.post(
                `${config.apiUrl}/phone/register_web_call`,
                {
                    agent_id: agentId,
                    conversation_id: conversationId,
                },
            );

            return response.data;
        } catch (err) {
            console.log('registerCall err', err);
            throw new Error(err);
        }
    }

    if (hasConversationIds(data, getChatIdFromUrl())) {
        return;
    }

    return (
        <div className="flex items-center gap-2.5 w-full">
            <div
                onClick={() => setActiveInput('files')}
                className="cursor-pointer bg-black dark:bg-white dark:bg-opacity-20 dark:hover:bg-opacity-50 dark:text-white dark:text-opacity-80 dark:hover-text-opacity-100 bg-opacity-10 hover:bg-opacity-20 rounded-full text-black text-opacity-60 hover:text-opacity-80"
            >
                <Plus height={24} width={24} strokeWidth={1} />
            </div>
            {config.environment !== 'production' && (
                <button
                    onClick={toggleConversation}
                    className="voice-chat-button"
                >
                    {isCalling ? 'Stop' : 'Voice'}
                </button>
            )}
            <textarea
                aria-label="chat-input"
                ref={textareaRef}
                rows={1}
                style={{ resize: 'none' }}
                className="dark:bg-white dark:bg-opacity-10 dark:hover:bg-opacity-20  w-full p-2  pl-3 bg-transparent scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-transparent rounded-xl border-2 border-black border-opacity-10 hover:border-opacity-30 dark:text-white focus:border-black focus:outline-none focus:border-opacity-100 dark:border-opacity-5 dark:hover:border-opacity-5  dark:focus:border-opacity-5"
                value={inputMessageText}
                onInput={e => setInputMessageText(e.target.value)}
                onKeyPress={e => {
                    if (e.key === 'Enter') {
                        if (!e.shiftKey) {
                            e.preventDefault();
                            if (!active) {
                                sendMessage(inputMessageText);
                            }
                        }
                    }
                }}
            />
            {!active && (
                <button
                    onClick={() => sendMessage(inputMessageText)}
                    className="absolute right-8 flex dark:hover:text-accentLight items-center border-none bg-transparent cursor-pointer hover:text-accent text-opacity-70 hover:text-opacity-100 text-black dark:text-white dark:text-opacity-50 p-0"
                    id="send-message-button"
                >
                    <SendDiagonalSolid width={20} height={20} />
                </button>
            )}
        </div>
    );
};

function hasConversationIds(conversations, id) {
    if (conversations === undefined) {
        return false;
    }

    // console.log('hasConversationIds', conversations, id);

    for (const item of conversations.pages.flat()) {
        if (item.conversation && item.conversation.id === id) {
            return true;
        }
    }
    return false; // No duplicates found
}

export default ChatInput;
