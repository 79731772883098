import React, { useContext, useState } from 'react';
import { useUserStateOnly } from '@/context/useUser.jsx';
import { GeneralContext } from '@/context/GeneralContext.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import RequestList from '@/pages/chat/RequestList';
import SideBarLogo from '@/assets/svg/SideBarLogo';
import { Settings } from 'iconoir-react';
import GuestWall from '@/components/GuestWall';
import { checkUserEmptyOrGuest } from '@/util/utils';
import { SettingsContent } from '@/pages/modals/SettingsModal';

const SidebarDesktop = () => {
    const [settingsOpen, setSettingsOpen] = useState(false);
    const { user, isLoading } = useUserStateOnly();
    const context = useContext(GeneralContext);
    const navigate = useNavigate();

    const location = useLocation(); // Get the current location object
    const currentPath = location.pathname;
    if (isLoading) {
        return;
    }
    if (currentPath === '/login' || currentPath === '/su') {
        return;
    }

    if (checkUserEmptyOrGuest(user)) {
        return <GuestWall />;
    }
    return (
        <div
            className="flex flex-col bg-white dark:bg-black"
            style={{ minWidth: '350px' }}
        >
            {settingsOpen === true ? (
                <SettingsContent setSettingsOpen={setSettingsOpen} />
            ) : (
                <>
                    <div
                        style={{ paddingRight: '20px' }}
                        className="flex flex-row py-5 justify-between"
                    >
                        <div onClick={() => navigate('/')}>
                            <SideBarLogo
                                color={context.dark ? 'white' : '#141718'}
                            />
                        </div>
                        <div className="text-black dark:text-white hover:text-opacity-70 dark:hover:text-opacity-80">
                            <Settings
                                aria-label="settings-button"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setSettingsOpen(s => !s)}
                                height={24}
                                width={24}
                            />
                        </div>
                    </div>
                    <RequestList />
                </>
            )}
        </div>
    );
};

export default SidebarDesktop;
