// axiosConfig.js
import axios from 'axios';
import config from './config';
import queryClient from '@/util/queryClient';

const RETRY_LIMIT = 3;
const RETRY_DELAY = 2000; // 1 second

// Custom function to delay the execution of a callback
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Base Axios instance with common configuration
const axiosBase = axios.create({
    baseURL: config.apiUrl,
    withCredentials: true,
});

const handleInvalidSession = error => {
    const { config } = error;
    const user = queryClient.getQueryData(['user']);

    const privilegedRoutes = [
        { method: 'post', route: '/conversation/' },
        { method: 'get', route: '/user/me' },
        { method: 'get', route: '/user/activity' },
        { method: 'get', route: '/phone_line' },
        { method: 'get', route: '/payment/subscription' },
        // { method: 'get', route: '/user/timezone' },
        { method: 'post', route: '/auth/logout' },
        { method: 'get', route: '/user/conversations' },
    ];

    const unauthorizedError = [401].includes(error.response?.status);
    const routeIsPrivileged = privilegedRoutes.some(
        ({ method, route }) =>
            config.method.toLowerCase() === method &&
            config.url.toLowerCase().startsWith(route),
    );

    const invalidSession = user && unauthorizedError && routeIsPrivileged;

    const notOnLoginPage = window.location.pathname !== '/login';

    console.log(
        'config.url',
        config.url,
        'unauthorizedError',
        unauthorizedError,
        'invalidSession',
        invalidSession,
        'routeIsPrivileged',
        routeIsPrivileged,
        'notOnLoginPage',
        notOnLoginPage,
    );

    if (invalidSession && notOnLoginPage) {
        window.location.href = '/login';
        queryClient.removeQueries(['user']);
    }

    return Promise.reject(error);
};

const retryInterceptor = async error => {
    const { config } = error;
    if (!config) return Promise.reject(error);

    // Initialize retry count if not present
    config.retryCount = config.retryCount || 0;

    // Check if the error status is service unavailable or if it's a timeout error
    const shouldRetry =
        error.response?.status === 503 || error.code === 'ECONNABORTED';

    if (shouldRetry && config.retryCount < RETRY_LIMIT) {
        config.retryCount += 1;

        // exponential backoff
        await sleep(RETRY_DELAY * config.retryCount);

        return axiosBase(config);
    }

    return Promise.reject(error);
};

const axiosWithRetry = axiosBase.create();
axiosWithRetry.interceptors.response.use(null, error => {
    return handleInvalidSession(error).catch(retryInterceptor);
});

export default axiosWithRetry;

export const axiosDefault = axiosBase.create();
axiosDefault.interceptors.response.use(null, handleInvalidSession);
