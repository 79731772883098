import React, { useContext, useState } from 'react';
import { GeneralContext } from '@/context/GeneralContext';
import SideBarLogo from '@/assets/svg/SideBarLogo';
import { NavArrowRight, Phone, Settings, ShareIos } from 'iconoir-react';
import useUser from '@/context/useUser';
import { usePhoneLine } from '@/hooks/usePhoneLine';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useNavigate } from 'react-router-dom';
import { useResponses } from '@/hooks/useResponses';
import { useCallHistory } from '@/hooks/useCallHistory';
import mixpanel from 'mixpanel-browser';
import { useQuery } from '@tanstack/react-query';
import { axiosDefault } from '@/util/axiosRetry';

function Hotline() {
    const context = useContext(GeneralContext);
    const [notif, setNotif] = useState(true);
    const [notification, setNotification] = useState(
        'Your Subi is almost ready. Go to response library to finish setting Subi up.',
    );
    const { user } = useUser();
    const { phoneLine } = usePhoneLine({ user });
    const navigate = useNavigate();
    const { all, attention, allLoading, attentionLoading } = useResponses(
        user?.voice_config?.phone_number,
    );
    const { calls, callsLoading } = useCallHistory(
        user?.voice_config?.phone_number,
    );
    const { data: userIntegrations } = useQuery({
        queryKey: ['integrations'],
        queryFn: async () => {
            const integrations = await axiosDefault.get(
                '/user/get_integrations',
            );
            return integrations.data;
        },
    });
    const handleShare = async e => {
        mixpanel.track('Hotline shared', { location: 'homepage' });

        e.stopPropagation();

        if (context.width < 769) {
            let shareData = {
                text: phoneLine?.agent?.name,
            };

            if (window.location.protocol === 'https:' && navigator?.share) {
                navigator
                    .share(shareData)
                    .then(() => {
                        console.log('shareData', shareData);
                    })
                    .catch(e => console.log('Error: ' + e));
            }
        }
    };

    function initiateCall() {
        window.location.href = 'tel:' + user?.voice_config?.phone_number;
    }

    return (
        <div
            style={{ height: context.height, width: context.width }}
            className={`flex-grow z-10 h-full flex justify-between
                    relative flex-col bg-white dark:bg-black `}
        >
            <div className="px-6">
                <div className={`flex py-5 justify-between items-center`}>
                    <div style={{ width: '45px' }}>
                        <Settings
                            style={{ cursor: 'pointer' }}
                            aria-label="navigation-button"
                            onClick={() => {
                                context.setOpenModal('settings');
                            }}
                            color={context.dark ? 'white' : 'black'}
                            strokeWidth={'1px'}
                        />
                    </div>
                    <SideBarLogo
                        height={'30'}
                        color={context.dark ? 'white' : '#141718'}
                    />
                    {context.width < 768 ? (
                        <div
                            className="relative cursor-pointer"
                            onClick={handleShare}
                        >
                            <ShareIos dark={context.dark} />
                        </div>
                    ) : (
                        <div style={{ width: '24px', height: '24px' }} />
                    )}
                </div>
                <div
                    style={{ paddingTop: '20px' }}
                    className="text-center text-2xl font-medium"
                >
                    {/* {user.goal ? user.goal : user.display_name + `'s Subi`} */}
                    {phoneLine?.agent?.name}
                </div>
                <div
                    style={{ paddingTop: '14px' }}
                    className="flex flex-col gap-4"
                >
                    <div className="flex flex-col gap-2.5 bg-white bg-opacity-10 pl-5 p-4 rounded-xl text-center font-medium ">
                        <div className="flex relative flex-row justify-center gap-4 items-center">
                            <button
                                className=" rounded-full absolute h-10 w-10 left-0 justify-center items-center flex"
                                onClick={initiateCall}
                            >
                                <Phone height={22} width={22} />
                            </button>
                            <div className="flex flex-row justify-center gap-2 items-center">
                                <div className="text-2xl">
                                    {user?.voice_config?.phone_number &&
                                        parsePhoneNumber(
                                            user?.voice_config?.phone_number,
                                        ).formatNational()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row gap-4">
                        <div
                            onClick={() => navigate('/call/')}
                            className="items-center cursor-pointer hover:bg-opacity-20 flex-grow flex flex-col  pb-6 w-auto pt-6 bg-white bg-opacity-10 rounded-xl text-center font-medium"
                        >
                            <div className="text-accentLight text-2xl">
                                {phoneLine && phoneLine.calls_today}
                            </div>
                            <div className="flex flex-row items-center">
                                <div className="text-xl">{'Calls today'}</div>
                                <NavArrowRight />
                            </div>
                        </div>

                        <div
                            onClick={() => context.setOpenModal('membership')}
                            className="relative cursor-pointer items-center hover:bg-opacity-20 flex-grow flex flex-col pb-6 w-auto pt-6 bg-white bg-opacity-10 rounded-xl text-center font-medium"
                        >
                            <div className="text-accentLight text-2xl">
                                {phoneLine && phoneLine.minutes_remaining}
                            </div>
                            <div className="flex flex-row items-center">
                                <div className="text-xl">{'Minutes left'}</div>
                                <NavArrowRight />
                            </div>
                            {/*{notif === true && (*/}
                            {/*    <NotificationCircle>1</NotificationCircle>*/}
                            {/*)}*/}
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div
                            onClick={() => {
                                navigate('/responses');
                            }}
                            className="relative  hover:bg-opacity-20 cursor-pointer p-4 justify-between flex flex-row gap-1 items-center   w-auto  bg-white bg-opacity-10 rounded-xl text-center font-medium"
                        >
                            <div className="text-xl">Response Library</div>
                            <div className="flex flex-row gap-2 items-center">
                                {phoneLine &&
                                    phoneLine.needs_attention_responses.length >
                                        0 && (
                                        <NotificationCircle>
                                            {
                                                phoneLine
                                                    .needs_attention_responses
                                                    .length
                                            }
                                        </NotificationCircle>
                                    )}
                                <NavArrowRight opacity={0.5} />
                            </div>
                        </div>
                        {/*<div*/}
                        {/*    onClick={() => navigate('/call/')}*/}
                        {/*    className="relative hover:bg-opacity-20 cursor-pointer p-4 justify-between flex flex-row gap-1 items-center   w-auto  bg-white bg-opacity-10 rounded-xl text-center font-medium"*/}
                        {/*>*/}
                        {/*    <div className="text-xl">Call History</div>*/}
                        {/*    <div className="flex flex-row gap-2 items-center">*/}
                        {/*        {notif === true && (*/}
                        {/*            <NotificationCircle>1</NotificationCircle>*/}
                        {/*        )}*/}
                        {/*        <NavArrowRight opacity={0.5} />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div
                            onClick={() =>
                                context.setOpenModal('customization')
                            }
                            className="relative hover:bg-opacity-20 cursor-pointer p-4 justify-between flex flex-row gap-1 items-center   w-auto  bg-white bg-opacity-10 rounded-xl text-center font-medium"
                        >
                            <div className="text-xl">Customization</div>
                            <div>
                                <NavArrowRight opacity={0.5} />
                            </div>
                        </div>
                        <div
                            onClick={() => context.setOpenModal('integrations')}
                            className="relative hover:bg-opacity-20 cursor-pointer p-4 justify-between flex flex-row gap-1 items-center   w-auto  bg-white bg-opacity-10 rounded-xl text-center font-medium"
                        >
                            <div className="text-xl">Integrations</div>
                            <div className="flex flex-row gap-2 items-center">
                                {' '}
                                {userIntegrations &&
                                    userIntegrations.length === 0 && (
                                        <NotificationCircle>
                                            !
                                        </NotificationCircle>
                                    )}
                                <NavArrowRight opacity={0.5} />
                            </div>
                        </div>
                        <div
                            onClick={() => {
                                navigate('/newchat');
                            }}
                            className="relative  hover:bg-opacity-20 cursor-pointer p-4 justify-between flex flex-row gap-1 items-center   w-auto  bg-white bg-opacity-10 rounded-xl text-center font-medium"
                        >
                            <div className="text-xl">Chat</div>
                            <div className="flex flex-row gap-2 items-center">
                                <NavArrowRight opacity={0.5} />
                            </div>
                        </div>
                        {context.width < 769 && (
                            <div
                                onClick={() => {
                                    navigate('/dashboard');
                                }}
                                className="relative  hover:bg-opacity-20 cursor-pointer p-4 justify-between flex flex-row gap-1 items-center   w-auto  bg-white bg-opacity-10 rounded-xl text-center font-medium"
                            >
                                <div className="text-xl">Chat History</div>
                                <div className="flex flex-row gap-2 items-center">
                                    <NavArrowRight opacity={0.5} />
                                </div>
                            </div>
                        )}
                        <a
                            href="https://agents4all.notion.site/Subi-Help-Guide-1054cea9dbcb80d1beede976747facd7"
                            className="cursor-pointer hover:bg-opacity-20 p-4 justify-between flex flex-row gap-1 items-center   w-auto  bg-white bg-opacity-10 rounded-xl text-center font-medium"
                        >
                            <div className="text-xl">Help</div>
                            <div>
                                <NavArrowRight opacity={0.5} />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            {attention &&
                attention.pages &&
                attention.pages[0] &&
                attention.pages[0].length > 0 && (
                    <div
                        onClick={() => navigate('/responses')}
                        className="cursor-pointer bg-accent font-medium flex flex-col gap-2.5 px-5 py-2.5 mb-7 text-center"
                    >
                        {notification}
                    </div>
                )}
        </div>
    );
}

const NotificationCircle = ({ children }) => {
    return (
        <div
            style={{
                width: '26px',
                padding: '3px 6px 0px 5px',
            }}
            className="bg-accent rounded-full font-medium"
        >
            {children}
        </div>
    );
};

export default Hotline;
